const score = {
   ragazzi: {
      id: 'ragazzi',
      points: 1,
      description: 'Relazione con i ragazzi'
   },
   competenza: {
      id: 'competenza',
      points: 1,
      description: 'Compenteze e sviluppo delle abilità'
   },
   staff: {
      id: 'staff',
      points: 1,
      description: 'Rapporto con lo staff'
   },
   impegno: {
      id: 'impegno',
      points: 1,
      description: 'Impegno: presenza, proattività, organizzazione'
   },
   fede: {
      id: 'fede',
      points: 1,
      description: "Testimonianza di Fede"
   },
   associazione: {
      id: 'associazione',
      points: 1,
      description: "Partecipazione associativa"
   },
   intenzionalita: {
      id: 'intenzionalita',
      points: 1,
      description: "Intenzionalità educativa"
   },
   civico: {
      id: 'civico',
      points: 1,
      description: "Impegno civico"
   },
};

export const globalPassword = 'ganimede';

/*const negative = (scoreItem) => {
   return {...scoreItem, points: -1 * scoreItem.points}
}*/

const changePoints = (scoreItem, amount) => {
   return {...scoreItem, points: amount}
}

const pages = [
   {
      id: '1',
      title: 'Verifica il tuo servizio da Capo',
      text: `Ti piace il servizio di quest'anno?`,
      choices: [
         {
            label: 'Certo!',
         },
         {
            label: 'Anche se non è la mia branca preferita mi sta piacendo',
         },
         {
            label: 'Non è tutto rose e fiori ma il bilancio è positivo',
         },
         {
            label: 'Sto avendo diverse difficoltà',
         },
         {
            label: 'Colgo l\'occasione per comunicare che smetto, mi trasferisco in Tailandia e apro un baretto sulla spiaggia',
         }
      ]
   },
    // IMPEGNO
   {
      id: '2',
      text: `Da ottobre hai saltato più di due attività (riunione di clan o attività domenicale)?`,
      choices: [
         {
            label: 'Sì',
            goTo: '3',
         },
         {
            label: 'No',
            goTo: '3',
            score: [changePoints(score.impegno, 0.5)]
         }
      ]
   },
   {
      id: '3',
      text: `Da ottobre hai saltato un'uscita o un campo/route?`,
      choices: [
         {
            label: 'Sì',
         },
         {
            label: 'No',
            score: [changePoints(score.impegno, 0.5)]
         }
      ]
   },
   {
      id: '4',
      text: `Nella suddivisione delle cose da fare in staff, ti prendi carico di`,
      choices: [
         {
            label: 'Troppe cose',
            score: [score.impegno]
         },
          {
            label: 'Molte cose',
             score: [score.impegno]
         },
         {
            label: 'Il giusto',
            score: [score.impegno]
         },
         {
            label: 'Poche cose',
         },
      ]
   },
   {
      id: '5',
      text: `In staff riesci a fare proposte nuove/creative`,
      choices: [
         {
            label: 'Mai',
         },
         {
            label: 'Raramente',
         },
         {
            label: 'Spesso',
            score: [score.impegno]
         },
         {
            label: 'Molto spesso',
            score: [score.impegno]
         },
      ]
   },
   {
      id: '6',
      text: `Riesci ad essere organizzato/a (hai sotto controllo il calendario delle attività, pianifichi le attività, ricordi scadenze e obiettivi)`,
      choices: [
         {
            label: 'Vero',
            score: [score.impegno]
         },
         {
            label: 'Falso',
         },
      ]
   },
   // RAGAZZI
   {
      id: '7',
      text: `Riesci a valorizzare le capacità e i talenti di almeno un terzo dei ragazzi che segui?`,
      choices: [
         {
            label: 'Sì',
            score: [score.ragazzi]
         },
         {
            label: 'Conosco le loro capacità ma non riesco a valorizzarle',
            score: [changePoints(score.ragazzi, 0.5)]
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '8',
      text: `Riesci a ad aiutare almeno un terzo dei ragazzi a fissare degli obiettivi per superare le loro difficoltà?`,
      choices: [
         {
            label: 'Sì',
            score: [score.ragazzi]
         },
         {
            label: 'Conosco le loro difficoltà ma non riesco a farli lavorare per obiettivi',
            score: [changePoints(score.ragazzi, 0.5)]
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '9',
      text: `Sei riuscito ad instaurare un rapporto privilegiato con qualcuno di loro?`,
      choices: [
         {
            label: 'Sì',
            score: [score.ragazzi]
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '10',
      text: `Ti senti di vedere il 5% di buono in tutti i ragazzi?`,
      choices: [
         {
            label: 'Beh, dai... alcuni sono proprio dei casi disperati',
         },
         {
            label: 'A volte devo sforzarmi ma direi di sì',
            score: [score.ragazzi]
         },
         {
            label: 'Certo!',
            score: [score.ragazzi]
         }
      ]
   },
    // STAFF
   {
      id: '11',
      text: `E' il tuo primo anno di servizio in questa unità?`,
      choices: [
         {
            label: 'Sì',
            goTo: '12'
         },
         {
            label: 'No',
            goTo: '13'
         }
      ]
   },
   {
      id: '12',
      text: `Quando hai bisogno di chiarimenti sul metodo o sulle dinamiche chiedi aiuto ad altri più esperti?`,
      choices: [
         {
            label: 'Sì',
            score: [score.staff],
            goTo: '14'
         },
         {
            label: 'A volte ma non sempre',
            score: [changePoints(score.staff, 0.5)],
            goTo: '14'
         },
         {
            label: 'No',
            goTo: '14'
         }
      ]
   },
   {
      id: '13',
      text: `Ti prendi cura dei capi meno esperti preoccupandoti che capiscano il metodo e il significato della proposta educativa?`,
      choices: [
         {
            label: 'Sì',
            score: [score.staff]
         },
         {
            label: 'A volte ma non sempre',
            score: [changePoints(score.staff, 0.5)],
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '14',
      text: `Sei attento/a alle esigenze degli altri membri dello staff, riesci a valorizzare i loro punti di forza?`,
      choices: [
         {
            label: 'Sì',
            score: [score.staff]
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '15',
      text: `A riunione di staff`,
      choices: [
         {
            label: 'A volte cambi argomento senza lasciare concludere quello precedente o saltando la scaletta condivisa',
         },
         {
            label: 'A volte usi troppe parole o ti ripeti per paura di non essere capito/a o ascoltato/a',
         },
         {
            label: 'Aiuti a mantenere il filo del discorso e ad arrivare al dunque',
            score: [score.staff]
         },
         {
            label: 'Riesci ad essere chiaro/a e sintetico/a quando prendi la parola',
            score: [score.staff]
         }
      ]
   },
   {
      id: '16',
      text: `Quando sorge una difficoltà/emergenza riesci a confrontarti con la tua staff per trovare una soluzione condivisa?`,
      choices: [
         {
            label: 'Sì',
            score: [changePoints(score.staff, 0.5)],
         },
         {
            label: 'A volte',
         },
         {
            label: 'No',
         }
      ]
   },
   {
      id: '17',
      text: `Riesci a dar spazio ai membri dello staff che faticano ad emergere (gli chiedi la loro opinione, gli proponi di prendere la parola, ecc...)?`,
      choices: [
         {
            label: 'Nello staff non ci sono persone che faticano ad emergere',
            score: [changePoints(score.staff, 0.5)],
         },
         {
            label: 'Sì',
            score: [changePoints(score.staff, 0.5)],
         },
         {
            label: 'Solo a volte',
         }
         ,
         {
            label: 'No',
         }
      ]
   },
    // COMPETENZA
   {
      id: '18',
      text: `Conosci a fondo gli strumenti metodologici della tua branca?`,
      choices: [
         {
            label: 'Sì',
            score: [score.competenza]
         },
         {
            label: 'mmmm... devo approfondirli meglio',
         }
      ]
   },
   {
      id: '19',
      text: `Le tue competenze sono adeguate per fare bene il tuo servizio (usare bene l'espressione in un racconto giungla, 
      insegnare a un ragazzo come si fa una legatura, insegnare come preparare un percorso leggendo la cartina, ecc...)?`,
      choices: [
         {
            label: 'Sì, sono molto sul pezzo',
            score: [score.competenza]
         },
         {
            label: 'Sì ma posso sempre migliorare',
            score: [score.competenza]
         },
         {
            label: 'Ho alcune lacune da colmare',
         },
         {
            label: 'Devo mettermi sotto perché non mi sento all\'altezza',
         }
      ]
   },
   {
      id: '20',
      text: `Quando devi progettare un'attività in cui sei poco preparato`,
      choices: [
         {
            label: 'Tendo a delegare ad altre persone della staff che sono più competenti nell\'ambito',
         },
         {
            label: 'Quando possibile cerco di imparare cose nuove dai manuali, da internet o da altre fonti',
            score: [score.competenza]
         },
         {
            label: 'Coinvolgo persone esperte per chiedere consigli e collaborazione',
            score: [score.competenza]
         },
         {
            label: 'La faccio come riesco',
         }
      ]
   },
   {
      id: '21',
      text: `Pensi di riuscire a trasmettere efficacemente le tue competenze ai ragazzi?`,
      choices: [
         {
            label: 'Sì, qualche volta ne vedo i risultati',
            score: [score.competenza]
         },
         {
            label: 'Provo a spiegare le cose ai ragazzi ma spesso non le assorbono',
         },
         {
            label: 'In genere non riesco o non ci provo nemmeno',
         }
      ]
   },
    // FEDE
   {
      id: '22',
      text: `Se pensi alla necessità di essere un Testimone di Fede credibile`,
      choices: [
         {
            label: 'Sono abbastanza tranquillo/a',
            score: [score.fede]
         },
         {
            label: 'Ho alcuni dubbi ma penso che faccia parte del proprio percorso spirituale porsi delle domande',
            score: [score.fede]
         },
         {
            label: 'Non so bene come gestire questa cosa',
         },
         {
            label: 'Mi sento insicuro/a e/o inadeguato/a',
         },
      ]
   },
   {
      id: '23',
      text: `Durante attività, uscite e campi riesci a collegare quello che facciamo alla Parola?`,
      choices: [
         {
            label: 'Sì, spesso',
            score: [score.fede]
         },
         {
            label: 'A volte ma con abbastanza naturalezza',
            score: [score.fede]
         },
         {
            label: 'Sono troppo preso dalle altre cose per pensarci',
         },
         {
            label: 'Mi sento a disagio a fare riferimenti spirituali fuori dai momenti dedicati',
         },
         {
            label: 'Il più delle volte non riesco',
         }
      ]
   },
   {
      id: '24',
      text: `Faccio qualcosa per tenere vivo il mio percorso di Fede (leggo, prego, mi confronto con gli altri, ...)?`,
      choices: [
         {
            label: 'Sì, in maniera piuttosto sistematica',
            score: [score.fede]
         },
         {
            label: 'Abbastanza ma vado un po\' a periodi',
            score: [score.fede]
         },
         {
            label: 'Mi piacerebbe ma faccio fatica',
         },
         {
            label: 'Non ho abbastanza tempo',
            score: [changePoints(score.fede, -1)]
         }
      ]
   },
   {
      id: '25',
      text: `Quando un ragazzo pone dei dubbi sulle posizioni della chiesa`,
      choices: [
         {
            label: 'Riesco ad affrontare il discorso mostrandogli il mio punto di vista senza sentirmi incoerente',
            score: [score.fede]
         },
         {
            label: 'Non è facile ma accolgo la sua domanda e se necessario mi impegno a creare un confronto con chi ha le idee più chiare di me',
            score: [score.fede]
         },
         {
            label: 'Sono spesso in difficoltà perché non ho un\'idea chiara o la mia opinione potrebbe sembrare in contrasto con la posizione della chiesa',
         },
         {
            label: 'Chiarisco che non posso rispondere a questo tipo di domande e rimando il discorso a quando è presente un parroco o un\'altra figura adeguata',
         },
         {
            label: 'Mi fingo morto',
         }
      ]
   },
    // ASSOCIAZIONE
   {
      id: '26',
      text: `Hai saltato più di una riunione di branca?`,
      choices: [
         {
            label: 'Sì',
         },
         {
            label: 'No',
            score: [score.associazione]
         },
      ]
   },
   {
      id: '27',
      text: `Negli ultimi 2 anni hai partecipato ad un evento associativo per capi?`,
      choices: [
         {
            label: 'Sì',
            score: [score.associazione]
         },
         {
            label: 'No',
         },
         {
            label: 'Non ricordo',
         },
      ]
   },
   {
      id: '28',
      text: `Partecipi spesso alle riunioni di CoCa? Ti impegni attivamente ad animare le riunioni o lasci che ci pensino gli altri?`,
      choices: [
         {
            label: 'Ho saltato più di due riunioni dall\'inizio dell\'anno',
         },
         {
            label: 'Partecipo spesso ma mi limito ad ascoltare il più delle volte',
            score: [score.associazione]
         },
         {
            label: 'Partecipo spesso e credo di dare il mio contributo facendo proposte e inserendomi nelle pattuglie quando necessario',
            score: [changePoints(score.associazione, 2)]
         },
      ]
   },
    // INTENZIONALITA' EDUCATIVA
   {
      id: '29',
      text: `Il fine del tuo servizio come capo è`,
      choices: [
         {
            label: 'Trovare il/la moroso/a',
         },
         {
            label: 'Passare del tempo di qualità insieme ai ragazzi e agli altri capi',
         },
         {
            label: 'Restituire quello che ho ricevuto dallo scoutismo',
         },
         {
            label: 'Aiutare i ragazzi a crescere come buoni cittadini',
            score: [score.intenzionalita]
         },
         {
            label: 'Accompagnare i ragazzi verso le scelte della partenza',
            score: [score.intenzionalita]
         },
      ]
   },
   {
      id: '30',
      text: `Quando progetti un'attività`,
      choices: [
         {
            label: 'Pensi a qualcosa di bello pescando dalle mie esperienze passate',
         },
         {
            label: 'Cerchi di inventare qualcosa di nuovo ed interessante',
         },
         {
            label: 'Ti dai un obiettivo educativo e poi e cerchi di raggiungerlo creando un\'attività',
            score: [score.intenzionalita]
         },
         {
            label: 'Parti da un obiettivo educativo facendo anche attenzione a cosa è stato scritto nel Programma di Unità',
            score: [score.intenzionalita]
         }
      ]
   },
   {
      id: '31',
      text: `E se ti dicessi PEG?`,
      choices: [
         {
            label: 'L\'ultimo che mi ha detto una cosa del genere ha fatto una brutta fine',
         },
         {
            label: 'Non so cosa sia',
         },
         {
            label: 'So di cosa stiamo parlando ma non ricordo bene cosa c\'è scritto',
         },
         {
            label: 'Ricordo diversi punti dell\'attuale PEG o comunque lo riprendo in mano abbastanza spesso',
            score: [score.intenzionalita]
         },
         {
            label: 'Mi preoccupo che le cose scritte nel PEG siano sviluppate nel programma di unità e nel programma di CoCa',
            score: [changePoints(score.intenzionalita, 2)]
         }
      ]
   },
    // IMPEGNO CIVICO
   {
      id: '32',
      text: `Ti preoccupi che il gruppo abbia una buona relazione con le realtà limitrofe (parrocchia, catechisti, comune, altre associazioni)?`,
      choices: [
         {
            label: 'Sì',
            score: [changePoints(score.civico, 2)]
         },
         {
            label: 'A volte',
            score: [score.civico]
         },
         {
            label: 'Difficilmente',
         },
         {
            label: 'Non è mio compito, sono i Capi Gruppo e gli Incaricati di Zona che se ne devono occupare',
            score: [changePoints(score.civico, -1)]
         }
      ]
   },
   {
      id: '33',
      text: `Ti senti di apparire come un buon cittadino agli occhi dei ragazzi? Dimostri interesse, impegno e attenzione verso le realtà esterne allo scoutismo?`,
      choices: [
         {
            label: 'Sì',
            score: [changePoints(score.civico, 2)],
            goTo: 'end'
         },
         {
            label: 'Abbastanza',
            score: [score.civico],
            goTo: 'end'
         },
         {
            label: 'Non molto',
            goTo: 'end'
         },
         {
            label: 'No',
            goTo: 'end'
         },
      ]
   },
   {
      id: 'end'
   }
];

export default pages;
