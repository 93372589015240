import React from 'react';
import { Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCalendar,
   faHandHoldingHeart,
   faBullhorn,
    faHandshake,
    faCross,
    faFlag,
   faBullseye,
    faMapMarkerAlt,
    faAward
} from '@fortawesome/free-solid-svg-icons';

import pages from '../pages.js';

export function getBonusScore(score) {
   score.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
   });
   const minValue = score[0];
   return minValue * 2;
}

function getPageById(id) {
   return pages.find(p => p.id === id)
}

function End({ totalScore, answers }) {
   const sections = [
      {
         label: 'Relazione con i ragazzi',
         id: 'ragazzi',
         variant: 'primary',
         icon: faHandHoldingHeart,
         maxScore: 4,
      },
      {
         label: 'Rapporto con lo staff',
         id: 'staff',
         variant: 'primary',
         icon: faHandshake,
         maxScore: 4,
      },
      {
         label: 'Impegno: presenza, proattività, organizzazione',
         id: 'impegno',
         variant: 'primary',
         icon: faCalendar,
         maxScore: 4,
      },
      {
         label: 'Testimonianza di Fede',
         id: 'fede',
         variant: 'primary',
         icon: faCross,
         maxScore: 4,
      },
      {
         label: 'Partecipazione associativa',
         id: 'associazione',
         variant: 'primary',
         icon: faFlag,
         maxScore: 4,
      },
      {
         label: 'Intenzionalità educativa',
         id: 'intenzionalita',
         variant: 'primary',
         icon: faBullseye,
         maxScore: 4,
      },
      {
         label: 'Impegno civico, cittadinanza attiva',
         id: 'civico',
         variant: 'primary',
         icon: faMapMarkerAlt,
         maxScore: 4,
      },
      {
         label: 'Compenteze e sviluppo delle abilità',
         id: 'competenza',
         variant: 'primary',
         icon: faAward,
         maxScore: 4,
      },
   ];

   sections.sort((a, b) => {
      const scoreA = totalScore[a.id]
      const scoreB = totalScore[b.id]
      if (scoreA > scoreB) return -1
      if (scoreA < scoreB) return 1
      return 0
   });
   sections.forEach(s => {
      const score = totalScore[s.id]
      if (score >= 3) s.variant = 'success'
      else if (score >= 1) s.variant = 'warning'
      else s.variant = 'danger'
   })

   return (
      <>
         <h1>Fine!</h1>
         <p>
            Congratulazioni, hai terminato la tua verifica! Ecco i tuoi
            punteggi:
         </p>
         <Table>
            <tbody>
               {sections.map(section => {
                  return (
                     <tr key={section.id}>
                        <td>
                           <FontAwesomeIcon icon={section.icon} />
                        </td>
                        <td>{section.label}</td>
                        <td>
                           <Badge pill variant={section.variant}>
                              {totalScore[section.id]} / {section.maxScore}
                           </Badge>
                        </td>
                     </tr>
                  );
               })}
            </tbody>
         </Table>
         <h2>Le tue risposte</h2>
         {answers.map(a => {
            const page = getPageById(a.questionId)
            const answer = page.choices[a.choiceIndex]
            console.log('page', page)
            console.log('answer', answer)
            let points = 0
            let section = null
            if (answer?.score && answer.score[0]) {
               points = answer.score[0].points
               section = <small>({answer.score[0].description} {points})</small>

            }

            return (
                <p key={page.id}>
                   <strong>{page.id}. {page.text}</strong><br />
                   {answer.label} {section}
                </p>
            )
         })}
      </>
   );
}

export default End;
